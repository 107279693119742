import React from 'react';
import { Link } from 'gatsby';
import { graphql } from 'gatsby';

import SEO from '../components/seo';
import MainContent from '../components/MainContent';
import { HeadingOne, HeadingThree } from '../components/Typography';

const IndexPage = ({ data }) => (
  <MainContent>
    <SEO title="Home" />
    <HeadingOne>Articles</HeadingOne>
    {data.allMarkdownRemark.edges.map(({ node }) => (
      <HeadingThree key={node.id}>
        <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
      </HeadingThree>
    ))}
  </MainContent>
);

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            title
            published(formatString: "D MMMM YYYY")
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

export default IndexPage;
